import { useEffect, lazy, Suspense } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';

import { useTelegram } from './hooks/useTelegram';

import './App.css';

const Homa = lazy(() => import('./pages/Homa/Homa.jsx'));
const Green = lazy(() => import('./pages/Green/Green.jsx'));
const Isa = lazy(() => import('./pages/Isa/Isa.jsx'));
const Imt = lazy(() => import('./pages/Imt/Imt.jsx'));
const Hypertens = lazy(() => import('./pages/Hypertens/Hypertens.jsx'));
const Vteo = lazy(() => import('./pages/Vteo/Vteo.jsx'));
const Calendar = lazy(() => import('./pages/Calendar/Calendar.jsx'));
const Home = lazy(() => import('./pages/Home/Home.jsx'));
const OvulationCalendar = lazy(() =>
  import('./pages/OvulationCalendar/OvulationCalendar.jsx')
);
const Registration = lazy(() =>
  import('./pages/Registration/Registration.jsx')
);
const Feedback = lazy(() => import('./pages/Feedback/Feedback.jsx'));
const CalendarMgt = lazy(() => import('./pages/CalendarMgt/CalendarMgt.jsx'));
const Mief5 = lazy(() => import('./pages/Mief5/Mief5.jsx'));
const Padua = lazy(() => import('./pages/Padua/Padua.jsx'));
const Ams = lazy(() => import('./pages/Ams/Ams.jsx'));
const Quest = lazy(() => import('./pages/Quest/Quest.jsx'));
const Questionnare = lazy(() => import('./pages/Questionnare/Questionnare.jsx'));

const RoutesArr = [
  {
    path: 'ovulation',
    element: <OvulationCalendar />,
  },
  {
    path: 'imt',
    element: <Imt />,
  },
  {
    path: 'homa',
    element: <Homa />,
  },
  {
    path: 'hypertens',
    element: <Hypertens />,
  },
  {
    path: 'green',
    element: <Green />,
  },
  {
    path: 'isa',
    element: <Isa />,
  },
  {
    path: 'vteo',
    element: <Vteo />,
  },
  {
    path: 'calendar',
    element: <Calendar />,
  },
  {
    path: 'registration',
    element: <Registration />,
  },
  {
    path: 'feedback',
    element: <Feedback />,
  },
  {
    path: 'calendar_mgt',
    element: <CalendarMgt />,
  },
  {
    path: 'mief',
    element: <Mief5 />,
  },
  {
    path: 'padua',
    element: <Padua />,
  },
  {
    path: 'ams',
    element: <Ams />,
  },
  {
    path: 'quest',
    element: <Quest />,
  },
  {
    path: 'questionnare',
    element: <Questionnare />,
  },
];

const App = () => {
  const { tg } = useTelegram();

  useEffect(() => tg.ready(), [tg]);
  return (
    <div className="app">
      <HashRouter>
        <Routes>
          <Route
            index
            element={
              <Suspense fallback={<>...</>}>
                <Home />
              </Suspense>
            }
          />
          {RoutesArr.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={<Suspense fallback={<>...</>}>{route.element}</Suspense>}
            />
          ))}
        </Routes>
      </HashRouter>
    </div>
  );
};

export default App;
